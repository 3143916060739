import React from 'react';
import {
    Box,
    Grid,
    Card,
    CardContent,
    Typography,
    IconButton,
    CircularProgress,
    useMediaQuery,
    Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import OverviewCard from './OverviewCard';
import MyChartComponent from './Chart';
import FindingsTable from './FindingsTable';
import MenuIcon from '@mui/icons-material/Menu';

const Dashboard = ({ data, loading, handleDrawerToggle }) => {
    const theme = useTheme();
    const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));

    if (loading || !data) {
        return ( <
            Box display = "flex"
            justifyContent = "center"
            alignItems = "center"
            height = "100vh" >
            <
            CircularProgress color = "primary" / >
            <
            /Box>
        );
    }

    return ( <
        Box padding = { isXsDown ? 2 : 4 }
        sx = {
            {
                bgcolor: 'background.default',
                color: 'text.primary',
                '.MuiCard-root': { bgcolor: 'background.paper', boxShadow: 3 },
                '.MuiButton-root': { margin: 1 },
            }
        } >
        <
        IconButton color = "inherit"
        onClick = { handleDrawerToggle }
        sx = {
            { marginRight: 2, display: isXsDown ? 'block' : 'none' } } >
        <
        MenuIcon / >
        <
        /IconButton>

        <
        Grid container spacing = { 3 } >
        <
        Grid item xs = { 12 }
        md = { 8 } >
        <
        Card raised >
        <
        CardContent >
        <
        Typography variant = "h6"
        gutterBottom >
        Reports Over Time <
        /Typography> <
        MyChartComponent data = { data.chartData }
        options = { data.chartOptions }
        /> <
        /CardContent> <
        /Card> <
        /Grid>

        <
        Grid item xs = { 12 }
        md = { 4 }
        container spacing = { 2 } > {
            data.overview.map((item, index) => ( <
                Grid item xs = { 12 }
                key = { index } >
                <
                OverviewCard title = { item.title }
                value = { item.value }
                /> <
                /Grid>
            ))
        } <
        /Grid>

        <
        Grid item xs = { 12 }
        container justifyContent = "flex-end" >
        <
        Button variant = "contained"
        color = "primary"
        onClick = {
            () => console.log('Report generation initiated') } >
        Generate Report <
        /Button> <
        /Grid>

        <
        Grid item xs = { 12 } >
        <
        Card raised >
        <
        CardContent >
        <
        Typography variant = "h6"
        gutterBottom >
        Latest Findings <
        /Typography> <
        FindingsTable findings = { data.findings }
        /> <
        /CardContent> <
        /Card> <
        /Grid> <
        /Grid> <
        /Box>
    );
};

export default Dashboard;