import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import {
    CircularProgress,
    CssBaseline,
    AppBar,
    Toolbar,
    IconButton,
    Avatar,
    Typography,
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import ProfilePage from './components/ProfilePage';
import Login from './components/login';
import './App.css';
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDrCHk6bFUXYuE30-5kDvlOrbKWZbIebts",
    authDomain: "pardx-275de.firebaseapp.com",
    projectId: "pardx-275de",
    storageBucket: "pardx-275de.appspot.com",
    messagingSenderId: "594635262555",
    appId: "1:594635262555:web:720905400193861abbcd7e",
    measurementId: "G-SD4SLJ2B76"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const theme = createTheme({
    palette: {
        primary: {
            main: '#1D4ED8',
        },
        background: {
            default: '#F1F5F9',
        },
    },
    typography: {
        fontFamily: 'San Francisco, Roboto, Open Sans, sans-serif',
    },
});

function App() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            axios.get('https://37fpp6g3-5000.euw.devtunnels.ms/api/data')
                .then(response => {
                    // Ensure response.data contains the expected data
                    if (response.data) {
                        setData(response.data);
                    } else {
                        // Handle the lack of data however you see fit
                        console.error('Data not found in response');
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [isAuthenticated]);


    if (loading) {
        return <div className = "loading-container" > < CircularProgress / > < /div>;
    }

    return ( <
            ThemeProvider theme = { theme } >
            <
            CssBaseline / >
            <
            BrowserRouter >
            <
            AppBar position = "static"
            color = "default"
            className = "app-bar" >
            <
            Toolbar >
            <
            Typography variant = "h6"
            color = "inherit"
            noWrap className = "toolbar-title"
            style = {
                { fontWeight: 'bold', fontSize: '24px', flexGrow: 1 }
            } >
            Pardx <
            /Typography> {
            isAuthenticated && ( <
                IconButton size = "large"
                edge = "end"
                component = { Link }
                to = "/profile"
                className = "profile-button" >
                <
                Avatar src = "/path-to-your-profile-image.jpg"
                alt = "Profile" / >
                <
                /IconButton>
            )
        } <
        /Toolbar> < /
    AppBar >

        <
        Routes >
        <
        Route path = "/"
    element = { isAuthenticated ? < Dashboard data = { data } /> : <Login auth={auth} setIsAuthenticated={setIsAuthenticated} / > }
    /> <
    Route path = "/profile"
    element = {
        isAuthenticated ? < ProfilePage / > : < Login auth = { auth }
        setIsAuthenticated = { setIsAuthenticated }
        />} / >
        <
        Route path = "/login"
        element = { < Login auth = { auth }
            setIsAuthenticated = { setIsAuthenticated }
            />} / > { /* Additional routes */ } <
            /Routes> < /
            BrowserRouter > <
            /ThemeProvider>
        );
    }

    export default App;