import React, { useRef, useEffect } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';

Chart.register(...registerables);

const MyChartComponent = ({ data }) => {
    const canvasRef = useRef(null);
    const chartRef = useRef(null);

    useEffect(() => {
        if (!canvasRef.current) return;
        const ctx = canvasRef.current.getContext('2d');

        // Assuming your chart container has a height, adjust as necessary.
        const height = ctx.canvas.clientHeight;

        const gradientFill = ctx.createLinearGradient(0, 0, 0, height);
        gradientFill.addColorStop(0, 'rgba(135, 206, 250, 0.6)'); // Starting color with opacity
        gradientFill.addColorStop(1, 'rgba(255, 255, 255, 0)'); // Ending color fully transparent

        if (chartRef.current) {
            chartRef.current.destroy(); // Destroy the previous chart
        }

        chartRef.current = new Chart(ctx, {
            type: 'line',
            data: {
                ...data,
                datasets: data.datasets.map(dataset => ({
                    ...dataset,
                    fill: 'start',
                    backgroundColor: gradientFill, // Apply the gradient here
                    borderColor: 'rgba(0, 123, 255, 1)', // Blue color for the line
                    pointBackgroundColor: 'rgba(0, 123, 255, 1)', // Blue color for points
                    pointBorderColor: '#fff',
                    pointBorderWidth: 2,
                    pointRadius: 5,
                    tension: 0.4 // Adjusted for a smoother curve
                }))
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                plugins: {
                    legend: {
                        display: false // Set to false if you don't want to show the legend
                    },
                    tooltip: {
                        mode: 'index',
                        intersect: false,
                        callbacks: {
                            label: function(context) {
                                let label = context.dataset.label || '';
                                if (label) {
                                    label += ': ';
                                }
                                if (context.parsed.y !== null) {
                                    label += new Intl.NumberFormat('en-US', {}).format(context.parsed.y);
                                }
                                return label;
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            // Customize time formatting here
                            tooltipFormat: 'MMM dd, yyyy'
                        },
                        title: {
                            display: true,
                            text: 'Date'
                        },
                        grid: {
                            display: false, // Hide grid lines
                        },
                    },
                    y: {
                        beginAtZero: true,
                        grid: {
                            display: true, // Show or hide grid lines
                            drawBorder: false,
                        },
                        title: {
                            display: true,
                            text: 'Value'
                        },
                        ticks: {
                            // Include a dollar sign in the ticks and format numbers with commas
                            callback: function(value) {
                                return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
                            }
                        }
                    }
                }
            },
        });

        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
        };
    }, [data]);

    return ( <
        div style = {
            { height: '400px', position: 'relative' }
        } >
        <
        canvas ref = { canvasRef }
        style = {
            { height: '100%', width: '100%' }
        }
        /> < /
        div >
    );
};

export default MyChartComponent;