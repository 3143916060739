import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material';

const FindingsTable = ({ findings }) => ( <
    TableContainer component = { Paper }
    sx = {
        { boxShadow: 'none', border: '1px solid #e0e0e0' } } >
    <
    Table sx = {
        { minWidth: 650 } } >
    <
    TableHead >
    <
    TableRow sx = {
        { '& th': { fontWeight: '600', color: '#333' } } } >
    <
    TableCell > Date < /TableCell> <
    TableCell align = "right" > Domain < /TableCell> <
    TableCell align = "right" > Type < /TableCell> <
    TableCell align = "right" > Detail < /TableCell> <
    /TableRow> <
    /TableHead> <
    TableBody > {
        findings.map((row) => ( <
            TableRow key = { row.id }
            sx = {
                {
                    '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
                    '&:hover': { backgroundColor: '#f1f1f1' }
                }
            } >
            <
            TableCell component = "th"
            scope = "row" > { row.date } <
            /TableCell> <
            TableCell align = "right" > { row.domain } < /TableCell> <
            TableCell align = "right" > { row.type } < /TableCell> <
            TableCell align = "right" > { row.detail } < /TableCell> <
            /TableRow>
        ))
    } <
    /TableBody> <
    /Table> <
    /TableContainer>
);

export default FindingsTable;