import React, { useState, useEffect } from 'react';
import { Avatar, Button, TextField, Container, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LogoutIcon from '@mui/icons-material/Logout';

const ProfilePage = () => {
    const navigate = useNavigate();
    const [profileInfo, setProfileInfo] = useState({
        name: '',
        image: '',
        companyDomain: '',
        email: '',
        phoneNumber: ''
    });

    // Fetch user data from a backend or local storage (this is a mockup for demonstration)
    useEffect(() => {
        // Replace with actual data fetching logic
        const userData = {
            name: 'John Doe',
            image: '/path-to-profile-image.jpg', // Replace with the actual path
            companyDomain: 'example.com',
            email: 'johndoe@example.com',
            phoneNumber: '123-456-7890'
        };
        setProfileInfo(userData);
    }, []);

    // Handlers for changes in the form
    const handleChange = (event) => {
        const { name, value } = event.target;
        setProfileInfo(prevInfo => ({
            ...prevInfo,
            [name]: value
        }));
    };

    // Handler for image change
    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setProfileInfo(prevInfo => ({
                    ...prevInfo,
                    image: e.target.result
                }));
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    // Navigation handlers
    const handleBackClick = () => {
        navigate('/'); // Navigate to the home page
    };

    const handleLogoutClick = () => {
        // Implement logout logic here
        console.log('Logout clicked');
        // After logout, redirect to login or home page
        navigate('/login');
    };

    // Save changes handler (mockup)
    const handleSaveChanges = () => {
        console.log('Saving changes:', profileInfo);
        // Implement logic to save changes to backend
    };

    return ( <
        Container maxWidth = "sm" >
        <
        Box my = { 4 }
        display = "flex"
        flexDirection = "column"
        alignItems = "center" >
        <
        Button startIcon = { < ArrowBackIcon / > }
        onClick = { handleBackClick } >
        Back <
        /Button> <
        Typography variant = "h4"
        component = "h1"
        gutterBottom >
        User Profile <
        /Typography> <
        Avatar src = { profileInfo.image }
        alt = "Profile"
        sx = {
            { width: 100, height: 100, mb: 2 }
        }
        /> <
        Button variant = "contained"
        component = "label"
        sx = {
            { mb: 2 }
        } >
        Change Profile Image <
        input type = "file"
        hidden onChange = { handleImageChange }
        /> < /
        Button > <
        TextField fullWidth label = "Name"
        name = "name"
        value = { profileInfo.name }
        onChange = { handleChange }
        margin = "normal" / >
        <
        TextField fullWidth label = "Company Domain"
        name = "companyDomain"
        value = { profileInfo.companyDomain }
        InputProps = {
            { readOnly: true }
        }
        margin = "normal" / >
        <
        TextField fullWidth label = "Email"
        name = "email"
        value = { profileInfo.email }
        onChange = { handleChange }
        margin = "normal" / >
        <
        TextField fullWidth label = "Phone Number"
        name = "phoneNumber"
        value = { profileInfo.phoneNumber }
        onChange = { handleChange }
        margin = "normal" / >
        <
        Button variant = "contained"
        color = "primary"
        sx = {
            { mt: 2 }
        }
        onClick = { handleSaveChanges } >
        Save Changes <
        /Button> <
        Button variant = "contained"
        color = "primary"
        sx = {
            { mt: 2 }
        }
        onClick = { handleLogoutClick }
        startIcon = { < LogoutIcon / > } >
        Logout <
        /Button> < /
        Box > <
        /Container>
    );
};

export default ProfilePage;